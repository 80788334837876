app.editPaymentProfile = () => {
  /*
  * First we need to determine which of the two forms is visible. Then
  * we trigger a submit event on the appropriate form.
  */
  const clickedSave = event => {
    event.preventDefault();
    const container = app.query('.payment-profile-control .switch-content-active');
    const forms =  container.querySelectorAll('form');
    if(forms.length == 1) {
      forms[0].dispatchSubmit();
    } else {
      container.querySelector('form.switch-content-active').dispatchSubmit();
    }
  };

  const init = () => {
    app.query('.payment-profile-save').addEventListener('click', clickedSave);
  };

  return init();
};

app.newPaymentProfile = () => {
  const clearErrors = form => {
    form.querySelectorAll('.form-error-inline, .form-error-base').forEach(element => {
      element.parentElement.removeChild(element);
    });
  };

  const buildPaymentObject = (form, isCreditCard) => {
    let paymentData = {};
    if (isCreditCard) {
      paymentData.cardData = collectCreditCardData(form);
    } else {
      paymentData.bankData = collectBankAccountData(form);
    }

    return Object.assign({ authData: app.authorizeNet }, paymentData);
  };

  const buildErrorElement = text => {
    const element = document.createElement('p');
    element.innerText = text;
    element.className = 'form-error-inline';
    return element;
  };

  const validatePaymentProfileForm = form => {
    let isValid = true;
    const fields = form.querySelectorAll('input[type=text], input[type=number]');
    fields.forEach(field => {
      if (field.value == null || field.value == '') {
        field.parentElement.appendChild(buildErrorElement('required'));
        isValid = false;
      }
    });
    return isValid;
  };

  const collectCreditCardData = form => ({
    fullName: form.querySelector('#name_cc').value,
    cardNumber: form.querySelector('#card_number').value,
    month: form.querySelector('#exp_month').value,
    year: form.querySelector('#exp_year').value,
    cardCode: form.querySelector('#cvv').value,
    zip: form.querySelector('#billing_zip').value
  });

  const collectBankAccountData = form => ({
    nameOnAccount: form.querySelector('#name_bank').value,
    accountType: form.querySelector('#account_type').value,
    accountNumber: form.querySelector('#account_number').value,
    routingNumber: form.querySelector('#routing_number').value
  });

  const handleSuccess = form => {
    const successPath = form.getAttribute('data-success');
    if (successPath === 'reload') {
      window.location.reload(true);
    } else {
      window.location = successPath;
    }
  };

  const persistPaymentInformation = (opaqueData, form) => {
    const url = form.getAttribute('data-submit-to');
    const body = {
      customerId: form.getAttribute('data-customer'),
      accountId: form.getAttribute('data-account'),
      paymentProfile: {
        descriptor: opaqueData.dataDescriptor,
        value: opaqueData.dataValue
      }
    };

    const request = {
      method: 'POST',
      body
    };

    app.fetch(url, request).then(response => {
      if (response.ok) {
        handleSuccess(form);
      } else {
        response.text().then(text => {
          form.appendChild(buildErrorElement(text));
        });
      }
    });
  };

  const paymentMethodResponseHandler = (response, form) => {
    if (response.messages.resultCode === 'Error') {
      response.messages.message.forEach(message => {
        form.appendChild(buildErrorElement(message.text));
      });
    } else {
      persistPaymentInformation(response.opaqueData, form);
    }
  };

  const submittedPayment = event => {
    event.preventDefault();

    const form = event.target;
    clearErrors(form);
    const radio = document.querySelector('input[name="payment[method]"]:checked')
    const isCreditCard = (radio == null) || radio.value == 'cc';

    if (!validatePaymentProfileForm(form)) {
      return;
    }

    const secureData = buildPaymentObject(form, isCreditCard);

    Accept.dispatchData(secureData, response => {
      paymentMethodResponseHandler(response, form);
    });
  };

  const init = () => {
    const forms = app.queryAll('.new-payment-profile');
    forms.forEach(element => element.addEventListener('submit', submittedPayment));
  };

  return init();
};
