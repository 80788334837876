Element.prototype.hide = function() {
  this.setVisible(false);
};

Element.prototype.show = function() {
  this.setVisible(true);
};

Element.prototype.setVisible = function(visible) {
  this.style.display = visible ? 'block' : 'none';
};

Element.prototype.isVisible = function() {
  return this.offsetParent !== null;
};
