/*
* Create an event in a browser safe way
*/
app.createEvent = (eventName) => {
  let event;

  if(typeof(Event) === 'function') {
    // Modern browsers
    event = new Event(eventName, { bubbles: true, cancelable: true });
  } else {
    // IE 11
    event = document.createEvent('Event');
    event.initEvent(eventName, true, true);
  }

  return event;
}
