const wrapperSelector = '.typeahead';
const hiddenFieldSelector = 'input[type=hidden].value';
const inputSelector = 'input[type=text]';
const resultsSelector = 'ul.typeahead-results';
const noResultsLiSelector = 'li.no-results';
const customErrorLiSelector = 'li.custom-error';
const resultLiSelector = 'li.result';
const defaultMinSearchChars = 3;
const defaultDebounceDelay = 300;

// Needs `function` instead of `=>` for proper `this` functionality
const debounce = function(callback, interval) {
  let delayed = null;
  return function() {
    let args = arguments;
    clearTimeout(delayed);
    delayed = setTimeout(function() {
      callback.apply(this, args);
    }, interval);
  };
};

const setNoResultsDisplay = (list, display = false) => {
  list.querySelector(noResultsLiSelector).setVisible(display);
};

const setCustomError = (list, message) => {
  errorLi = list.querySelector(customErrorLiSelector);
  if (message === null || message.length === 0) {
    errorLi.textContent = '';
    errorLi.setVisible(false);
  } else {
    errorLi.textContent = message;
    errorLi.setVisible(true);
  }
};

const clearCustomError = list => setCustomError(list, null);

const clearResults = (list, onClick) => {
  let resultsLis = list.querySelectorAll(resultLiSelector) || [];
  resultsLis.forEach(li => {
    li.removeEventListener('click', onClick);
    list.removeChild(li);
  });
};

const handleResults = (list, onClick, results) => {
  setNoResultsDisplay(list, false);
  clearResults(list, onClick);
  results.forEach(result => {
    let child = document.createElement('li');
    child.id = result.id;
    child.className = 'result';
    child.textContent = result.display_value || result.full_name;
    child.addEventListener('click', onClick);
    list.appendChild(child);
  });
};

const handleNoResults = (list, onClick) => {
  clearResults(list, onClick);
  setNoResultsDisplay(list, 'block');
};

const buildSearch = (input, list, hiddenField, resultClick) => {
  let url = input.getAttribute('data-search-url');

  const executeSearch = debounce(search => {
    const request = { method: 'GET' };
    app.fetch(`${url}?search=${search}`, request).then(response => {
      if (response.ok) {
        response.json().then(json => {
          clearCustomError(list);
          if (Array.isArray(json.results) && json.results.length > 0) {
            handleResults(list, resultClick, json.results);
          } else {
            handleNoResults(list, resultClick);
          }
        });
      } else {
        setCustomError(list, 'Something went wrong.');
      }
    });
  }, defaultDebounceDelay);
  return event => {
    let currentSearch = (input.value || '') + (event.key || '');
    if (currentSearch.length >= defaultMinSearchChars) {
      executeSearch(currentSearch);
    }
  };
};

const buildDeleteCheck = (input, list, hiddenField, resultClick) => {
  const DELETE = 46;
  const BSPACE = 8;
  return event => {
    let key = (key = event.keyCode || event.charCode);
    let currentValue = input.value || '';
    if ((key === DELETE || key === BSPACE) && currentValue.length <= defaultMinSearchChars) {
      clearResults(list, resultClick);
      setNoResultsDisplay(list, 'none');
      clearCustomError(list);
    }
  };
};

const buildListeners = (input, list, hiddenField) => {
  const resultClick = event => {
    resultLi = event.target;
    hiddenField.value = resultLi.id;
    input.value = resultLi.textContent;
    clearResults(list, resultClick);
    clearCustomError(list);
  };

  input.addEventListener('keypress', buildSearch(input, list, hiddenField, resultClick));
  input.addEventListener('keyup', buildDeleteCheck(input, list, hiddenField, resultClick));
};

const setupTypeahead = element => {
  const input = element.querySelector(inputSelector);
  const resultsListElement = element.querySelector(resultsSelector);
  const hiddenField = element.querySelector(hiddenFieldSelector);
  buildListeners(input, resultsListElement, hiddenField);
};

const init = () => {
  app.queryAll(wrapperSelector).forEach(setupTypeahead);
};

document.addEventListener('DOMContentLoaded', init);
