window.app = {};

/*
* Call each initializer only once
*/
const init = () => {
  const initializers = [];
  app.queryAll('[data-init]').forEach(el => {
    initializers.push(el.getAttribute('data-init'));
  });
  initializers.filter((value, index, array) => array.indexOf(value) === index).forEach(i => {
    app[i].call();
  });
};

document.addEventListener('DOMContentLoaded', init);

const requireAll = r => {
  r.keys().forEach(r);
};
requireAll(require.context('.', true, /\.js$/));
