document.addEventListener('DOMContentLoaded', () => {
  app.queryAll('input.date-picker').forEach(initDatePicker);
});

const initDatePicker = element => {
  new Pikaday({
    theme: 'sec-force',
    field: element,
    format: 'MM/DD/YYYY',
    minDate: extractDate(element, 'data-min'),
    maxDate: extractDate(element, 'data-max')
  });
};

const extractDate = (field, attr) => {
  let m = moment(field.getAttribute(attr), 'YYYY-MM-DD');
  if (m.isValid()) {
    return m.toDate();
  }
};
