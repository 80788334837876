const inputSelector = 'input[type=checkbox]';

const clickedToggle = (element, input) => () => {
  element.classList.toggle('on');

  input.checked = element.classList.contains('on');

  const changeEvent = app.createEvent('change');
  input.dispatchEvent(changeEvent);
  element.dispatchEvent(changeEvent);
};

const setupToggle = element => {
  const input = element.querySelector(inputSelector);
  element.addEventListener('click', clickedToggle(element, input));

  // Set state based on check box value
  if (input.checked) {
    element.classList.add('on');
  }

  // Enable transitions
  setTimeout(() => {
    element.classList.add('animated');
  }, 500);
};

const setupCheckEnables = input => {
  input.addEventListener('change', () => {
    const other = app.query(input.getAttribute('data-enables'));
    other.disabled = !input.checked;
  });
};

const init = () => {
  app.queryAll('.toggle').forEach(setupToggle);
  app.queryAll('input[data-enables]').forEach(setupCheckEnables);
};

document.addEventListener('DOMContentLoaded', init);
