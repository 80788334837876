const forEachPolyFill = function(callback, context) {
  for (let i = 0; i < this.length; i++) {
    callback.apply(context, [this[i], i, this]);
  }
};

if (typeof Array.prototype.forEach !== 'function') {
  Array.prototype.forEach = forEachPolyFill;
}

if (typeof NodeList.prototype.forEach !== 'function') {
  NodeList.prototype.forEach = forEachPolyFill;
}

if (typeof Array.prototype.last !== 'function') {
  Array.prototype.last = function() {
    return this[this.length - 1];
  };
}
