/*
* Convenience methods for query selectors, because
* shorter is nicer
*/
app.query = selector => document.querySelector(selector);
app.queryAll = selector => document.querySelectorAll(selector);
app.queryArr = selector => Array.from(document.querySelectorAll(selector));

// Find a parent element that matches the selector
//
// var input = document.querySelector(...)
// var form = input.queryParent('form')
//
Element.prototype.queryParent = function(selector) {
  if (!this.matches(`${selector} ${this.tagName}`)) {
    return null;
  }
  let target = this.parentElement;
  while (target !== null) {
    if (target.matches(selector)) {
      return target;
    }
    target = target.parentElement;
  }
};

/*
* Matches polyfill
*
* https://developer.mozilla.org/en-US/docs/Web/API/Element/matches
*/
if (!Element.prototype.matches) {
  Element.prototype.matches =
    Element.prototype.matchesSelector ||
    Element.prototype.mozMatchesSelector ||
    Element.prototype.msMatchesSelector ||
    Element.prototype.oMatchesSelector ||
    Element.prototype.webkitMatchesSelector ||
    function(s) {
      const matches = (this.document || this.ownerDocument).querySelectorAll(s);
      let i = matches.length;
      while (--i >= 0 && matches.item(i) !== this) {
        //
      }
      return i > -1;
    };
}
