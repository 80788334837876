import numberToCurrency from '../modules/format_currency';

app.newPayment = () => {
  let state = {
    method: 'charge',
    paymentAmount: ''
  };

  const renderPaymentMethod = () => {
    let payProfileRow = app.query('.pay-profile-row');

    if (state.method === 'charge') {
      payProfileRow.show();
    } else {
      payProfileRow.hide();
    }
  };

  const updatePaymentAmountDisplay = (amount) => {
    const paymentDisplayEl = app.query('#payment_display');
    paymentDisplayEl.innerHTML = 'Payment Amount: ' + numberToCurrency(amount);
  };

  const renderPaymentPeriod = () => {
    const customPaymentEl = app.query('.custom-payment-amount');
    let amount = state.paymentAmount === 'custom' ? 0 : state.paymentAmount;

    if (state.paymentAmount === 'custom') {
      customPaymentEl.show();
      app.query('#payment_display').hide();
    } else {
      customPaymentEl.hide();
      app.query('#payment_display').show();
      document.getElementsByClassName('payment-amount')[0].value = state.paymentAmount;
    }

    updatePaymentAmountDisplay(amount);
  };

  const changedPaymentMethod = () => {
    state.method = app.query('input[name="payment[payment_method]"]:checked').value;
    renderPaymentMethod();
  };

  const changedPeriodMethod = e => {
    state.paymentAmount = e.target.value;
    renderPaymentPeriod();
  };

  const equipmentPaymentSetup = () => {
    app.query('.custom-payment-amount').show();
    app.query('#payment_display').hide();
  };

  const normalizePaymentValues = (options) => {
    for (let i = 0; i < options.length; i++) {
      if (options[i].value !== 'custom') {
        options[i].value = parseFloat(Math.round(options[i].value * 100) / 100).toFixed(2);
      }
    }
  };

  const init = () => {
    app.queryAll('input[name="payment[payment_method]"]').forEach(el => {
      el.addEventListener('change', changedPaymentMethod);
    });

    const paymentPeriodSelect = app.query('select[name="payment[payment_period]"]');
    if (paymentPeriodSelect) {
      normalizePaymentValues(paymentPeriodSelect.options);
      paymentPeriodSelect.addEventListener('change', changedPeriodMethod);
      state.paymentAmount = app.query('select[name="payment[payment_period]"]').value;
      renderPaymentPeriod();
    } else {
      equipmentPaymentSetup();
    }
  };

  return init();
};
