const loadPaymentDescription = element => {
  element.innerHTML = '<em>loading...</em>';

  const url = element.getAttribute('data-payment');
  app.fetch(url).then(response => {
    if (response.ok) {
      response.text().then(text => {
        element.innerText = text;
      });
    } else {
      element.innerText = response.statusText;
    }
  });
};

const init = () => {
  app.queryAll('[data-payment]').forEach(loadPaymentDescription);
};

document.addEventListener('DOMContentLoaded', init);
