/*
* This pack contains logic that pertains to the app as a whole
* and is used on both the customer and manager facing portals.
*/

import '../common';
import 'es6-promise/dist/es6-promise.auto.js';
import 'rails-validator/dist/rails-validator';
import Rails from 'rails-ujs';
Rails.start();
