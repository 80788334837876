import Rails from 'rails-ujs';

/*
* Clicking a tagged button will set the tag on the enclosing form element
* This enables logic based on which submit button was clicked
*/
const clickTaggedButton = event => {
  const tag = event.target.getAttribute('data-tag');
  event.target.queryParent('form').setAttribute('data-tag', tag);
};

const changedAutoSubmit = event => {
  if (event.target.value === null || event.target.value === '') {
    return;
  }
  const form = event.target.queryParent('form');
  if (form.getAttribute('data-remote') === 'true') {
    form.dispatchSubmit();
  } else {
    form.submit();
  }
};

document.addEventListener('DOMContentLoaded', () => {
  app.queryAll('.button[data-tag]').forEach(element => {
    element.addEventListener('click', clickTaggedButton);
  });

  app.queryAll('.autosubmit').forEach(element => {
    element.addEventListener('change', changedAutoSubmit);
  });

  app.queryAll('form[data-remote=true]').forEach(element => {
    element.addEventListener('remote', Rails.handleRemote);
  });
});

/*
* Trigger a rails-ujs remote submission
*
* This differs from using the built in form.submit() method
* in one key way: Dispatching an event will set off the
* rails-ujs remote submission event handling code, while
* form.submit() will do a standard HTTP submit action.
*/
Element.prototype.dispatchSubmit = function() {
  if (this.tagName !== 'FORM') {
    return;
  }

  const eventName = this.getAttribute('data-remote') === 'true' ? 'remote' : 'submit';
  const event = app.createEvent(eventName);
  this.dispatchEvent(event);
};
