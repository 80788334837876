import 'whatwg-fetch/fetch';
import Rails from 'rails-ujs';

/*
* This convenience method calls the Fetch API with
* some sensible defaults
*
* https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API
*/
app.fetch = (url, init) => {
  init = init || {};

  // Include cross site scripting header
  init.headers = init.headers || {};
  init.headers['X-CSRF-Token'] = Rails.csrfToken();

  // Include cookies with the request
  init.credentials = 'same-origin';

  // Automatically pass objects as JSON
  if (init.body && init.body.constructor == Object) {
    init.body = JSON.stringify(init.body);
    init.headers['Content-Type'] = 'application/json';
    init.headers['Key-Inflection'] = 'camel';
  }

  return fetch(url, init);
};
