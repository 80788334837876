/*
* Switch between blocks of content with links
*
* Link with data-switch-to attribute should map to the
* index of one of the .switch-content containers
*
* .switcher
*   .switch-content
*     p Some content
*     a[data-switch-to=1] Go to next content
*   .switch-content
*     p Some other content
*     a[data-switch-to=0] Go to first content
*/

const clickedSwitchTo = event => {
  // Exclude radio buttons as their default behavior
  // as their default event controls checking/unchecking fields
  if (event.target.type !== 'radio') {
    event.preventDefault();
  }

  /*
  * Only two .switch-content classes can operate
  * inside of .switcher, as such we need to ignore
  * any elements that show up as a result of nested
  * switch elements
  */
  const elementLimit = 2;

  const parent = event.target.queryParent('.switcher');
  const switchTo = parseInt(event.target.getAttribute('data-switch-to'), 10);
  parent.querySelectorAll('.switch-content').forEach((element, index) => {
    if (switchTo === index) {
      element.classList.add('switch-content-active');
    } else if (index < elementLimit) {
      element.classList.remove('switch-content-active');
    }
  });
};

const initSwitcher = container => {
  container.querySelector('.switch-content').classList.add('switch-content-active');
  container.querySelectorAll('[data-switch-to]').forEach(element => {
    element.addEventListener('click', clickedSwitchTo);
  });
};

const init = () => {
  app.queryAll('.switcher').forEach(initSwitcher);
};

document.addEventListener('DOMContentLoaded', init);
